.pl-0 {
  padding-left: 0px !important;
}

/* New Button starts here */

.input-div {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 3px solid var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 0px 100px var(--yellow), inset 0px 0px 10px var(--yellow),
    0px 0px 5px var(----second);
  animation: flicker 2s linear infinite;
}

.icon {
  color: var(--yellow);
  /* font-size: 3rem !important; */
  cursor: pointer;
  animation: iconflicker 2s linear infinite;
  /* box-shadow: inset 0 0 50px yellow, inset 20px 0 80px #36373f,
    inset -20px 0 80px yellow, inset 20px 0 300px #36373f,
    inset -20px 0 300px yellow, 0 0 50px black, -10px 0 80px #36373f,
    10px 0 80px yellow; */
  /* animation: glow 1s ease infinite; */
  /* text-shadow: 0px 1px 11px #36373f, 1px 12px 13px #36373f, 1px 6px 12px #36373f !important; */
}

@keyframes glow {
  from {
    color: grey;
  }
  to {
    color: white;
    text-shadow: 0 0 5px #32ff7e, 0 0 25px #32ff7e, 0 0 50px #32ff7e,
      0 0 100px #32ff7e;
  }
}

.icon:hover {
  color: var(--yellow);
}

.input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

@keyframes flicker {
  0% {
    border: 2px solid rgb(255, 255, 0, 1);
    box-shadow: 0px 0px 100px rgb(255, 255, 0, 1),
      inset 0px 0px 10px rgb(255, 255, 0, 1), 0px 0px 5px rgb(41, 36, 36);
  }

  5% {
    border: none;
    box-shadow: none;
  }

  10% {
    border: 2px solid rgb(255, 255, 0, 1);
    box-shadow: 0px 0px 100px rgb(255, 255, 0, 1),
      inset 0px 0px 10px rgb(255, 255, 0, 1), 0px 0px 5px rgb(41, 36, 36);
  }

  25% {
    border: none;
    box-shadow: none;
  }

  30% {
    border: 2px solid rgb(255, 255, 0, 1);
    box-shadow: 0px 0px 100px rgb(255, 255, 0, 1),
      inset 0px 0px 10px rgb(255, 255, 0, 1), 0px 0px 5px rgb(41, 36, 36);
  }

  100% {
    border: 2px solid rgb(255, 255, 0, 1);
    box-shadow: 0px 0px 100px rgb(255, 255, 0, 1),
      inset 0px 0px 10px rgb(255, 255, 0, 1), 0px 0px 5px rgb(41, 36, 36);
  }
}

@keyframes iconflicker {
  0% {
    opacity: 1;
    color: yellow;
    text-shadow: 0 0 5px #36373f, 0 0 25px grey, 0 0 50px #36373f,
      0 0 100px gray;
  }

  5% {
    opacity: 0.2;
  }

  10% {
    opacity: 1;
    color: yellow;
    text-shadow: 0 0 5px #36373f, 0 0 25px #36373f, 0 0 50px #36373f,
      0 0 100px #36373f;
  }

  25% {
    opacity: 0.2;
  }

  30% {
    opacity: 1;
    color: yellow;
    text-shadow: 0 0 5px #36373f, 0 0 25px #36373f, 0 0 50px #36373f,
      0 0 100px #36373f;
  }

  100% {
    opacity: 1;
    color: lightslategrey;
    text-shadow: 0 0 5px yellow, 0 0 25px yellow, 0 0 50px yellow,
      0 0 100px yellow;
  }
}
@media only screen and (min-width: 200px) and (max-width: 576px) {
  .input-div {
    height: 2.5rem;
    width: 2.5rem;
  }
  .icon {
    font-size: 2rem !important;
  }
}

/* scrollbar */
.frame {
  max-height: 150px !important;
  overflow-y: auto !important;
}
.frame::-webkit-scrollbar {
  -webkit-appearance: none !important;
  display: block !important;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px !important;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px !important;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  border: 1px solid rgba(54, 51, 51, 0.5) !important; /* should match background, can't be transparent */
  background-color: rgba(54, 51, 51, 0.5) !important;
}

.frame::-webkit-scrollbar-track {
  background-color: rgb(218, 218, 167) !important;
  border-radius: 8px !important;
}
/* Button Ends here */
.swiper.square-swiper.artwork-swipper,
.swiper.square-swiper {
  width: 20cm !important;
  height: 19cm !important;
  border-radius: 20px !important;
}
.bg-yellow {
  background-color: var(--yellow) !important;
}

.TextFieldWrapper input:-webkit-autofill {
  background-color: #1b1d21;
  -webkit-box-shadow: 0 0 0 30px #1b1d21 inset !important;
  -webkit-text-fill-color: var(--yellow) !important;
}

.TextFieldWrapperDark input:-webkit-autofill {
  background-color: var(--yellow);
  -webkit-box-shadow: 0 0 0 30px var(--yellow) inset !important;
  -webkit-text-fill-color: #1b1d21 !important;
}

.one-line-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  text-wrap: nowrap !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.text-yellow {
  color: var(--yellow) !important;
}

.border-yellow {
  border-color: var(--yellow) !important;
}

.border-none {
  border: none !important;
}

.bg-dark {
  background-color: #141416;
}

.opacity-5{
  opacity: 0.5;
}

.bg-light-dark {
  background-color: #36373f !important;
}

.border-circle {
  border-radius: 50%;
}

.text-gray {
  color: #a0a096 !important;
}

.text-dark {
  color: #141416 !important;
}

.text-blue {
  color: #1334e3 !important;
}

.word-break-all {
  word-break: break-all !important;
}

.letter-space-2 {
  letter-spacing: 2px !important;
}

.text-orange {
  color: rgb(235, 110, 38) !important;
}

.heading-level-1 {
  font-size: 3rem !important;
}

.heading-level-2 {
  font-size: 2.5rem !important;
}

.heading-level-3 {
  font-size: 2rem !important;
}

.heading-level-4 {
  font-size: 1.5rem !important;
}

.text-level-1 {
  font-size: 1.8rem !important;
}

.text-level-2 {
  font-size: 1.6rem !important;
}

.text-level-3 {
  font-size: 1.4rem !important;
}

.text-level-4 {
  font-size: 1.2rem !important;
}

.text-level-5 {
  font-size: 1rem !important;
}

.text-level-6 {
  font-size: 0.8rem !important;
}

.text-level-7 {
  font-size: 0.6rem !important;
}

.line-hight-08 {
  line-height: 0.8 !important;
}

.line-hight-1 {
  line-height: 1 !important;
}

.line-hight-12 {
  line-height: 1.2 !important;
}

.line-hight-15 {
  line-height: 1.5 !important;
}

.line-hight-17 {
  line-height: 1.7 !important;
}

.line-hight-2 {
  line-height: 2 !important;
}

.fw-1 {
  font-weight: 100;
}

.fw-2 {
  font-weight: 200;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.fw-9 {
  font-weight: 900;
}

@media only screen and (min-width: 1600px) {
  .swiper.square-swiper.artwork-swipper,
  .swiper.square-swiper {
    width: 20cm !important;
    height: 19cm !important;
    border-radius: 20px !important;
  }
  .heading-level-1 {
    font-size: 3.3rem !important;
  }

  .heading-level-2 {
    font-size: 2.8rem !important;
  }

  .heading-level-3 {
    font-size: 2.3rem !important;
  }

  .heading-level-4 {
    font-size: 1.8rem !important;
  }

  .text-level-1 {
    font-size: 2rem !important;
  }

  .text-level-2 {
    font-size: 1.8rem !important;
  }

  .text-level-3 {
    font-size: 1.6rem !important;
  }

  .text-level-4 {
    font-size: 1.4rem !important;
  }

  .text-level-5 {
    font-size: 1.2rem !important;
  }

  .text-level-6 {
    font-size: 1rem !important;
  }

  .text-level-7 {
    font-size: 0.8rem !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1600px) {
  .swiper.square-swiper.artwork-swipper,
  .swiper.square-swiper {
    width: 15cm !important;
    height: 14.5cm !important;
    border-radius: 20px !important;
  }
  .heading-level-1 {
    font-size: 3rem !important;
  }

  .heading-level-2 {
    font-size: 2.5rem !important;
  }

  .heading-level-3 {
    font-size: 2rem !important;
  }

  .heading-level-4 {
    font-size: 1.5rem !important;
  }

  .text-level-1 {
    font-size: 1.8rem !important;
  }

  .text-level-2 {
    font-size: 1.6rem !important;
  }

  .text-level-3 {
    font-size: 1.4rem !important;
  }

  .text-level-4 {
    font-size: 1.2rem !important;
  }

  .text-level-5 {
    font-size: 1rem !important;
  }

  .text-level-6 {
    font-size: 0.8rem !important;
  }

  .text-level-7 {
    font-size: 0.6rem !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .heading-level-1 {
    font-size: 2.8rem !important;
  }

  .heading-level-2 {
    font-size: 2.3rem !important;
  }

  .heading-level-3 {
    font-size: 1.8rem !important;
  }

  .heading-level-4 {
    font-size: 1.3rem !important;
  }

  .text-level-1 {
    font-size: 1.7rem !important;
  }

  .text-level-2 {
    font-size: 1.5rem !important;
  }

  .text-level-3 {
    font-size: 1.3rem !important;
  }

  .text-level-4 {
    font-size: 1.1rem !important;
  }

  .text-level-5 {
    font-size: 0.9rem !important;
  }

  .text-level-6 {
    font-size: 0.8rem !important;
  }

  .text-level-7 {
    font-size: 0.7rem !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .swiper.square-swiper.artwork-swipper,
  .swiper.square-swiper {
    width: 12cm !important;
    height: 11.5cm !important;
    border-radius: 20px !important;
  }
  .heading-level-1 {
    font-size: 2.6rem !important;
  }

  .heading-level-2 {
    font-size: 2.1rem !important;
  }

  .heading-level-3 {
    font-size: 1.6rem !important;
  }

  .heading-level-4 {
    font-size: 1.1rem !important;
  }

  .text-level-1 {
    font-size: 1.5rem !important;
  }

  .text-level-2 {
    font-size: 1.35rem !important;
  }

  .text-level-3 {
    font-size: 1.2rem !important;
  }

  .text-level-4 {
    font-size: 1.15rem !important;
  }

  .text-level-5 {
    font-size: 0.9rem !important;
  }

  .text-level-6 {
    font-size: 0.8rem !important;
  }

  .text-level-7 {
    font-size: 0.7rem !important;
  }
}
@media only screen and (min-width: 420px) and (max-width: 576px) {
  .swiper.square-swiper.artwork-swipper,
  .swiper.square-swiper {
    width: 10cm !important;
    height: 9.8cm !important;
    border-radius: 20px !important;
  }
  .heading-level-1 {
    font-size: 2.4rem !important;
  }

  .heading-level-2 {
    font-size: 1.9rem !important;
  }

  .heading-level-3 {
    font-size: 1.4rem !important;
  }

  .heading-level-4 {
    font-size: 0.9rem !important;
  }

  .text-level-1 {
    font-size: 1.4rem !important;
  }

  .text-level-2 {
    font-size: 1.3rem !important;
  }

  .text-level-3 {
    font-size: 1.2rem !important;
  }

  .text-level-4 {
    font-size: 1.1rem !important;
  }

  .text-level-5 {
    font-size: 1rem !important;
  }

  .text-level-6 {
    font-size: 0.9rem !important;
  }

  .text-level-7 {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 420px) {
  .swiper.square-swiper.artwork-swipper,
  .swiper.square-swiper {
    width: 9cm !important;
    height: 8.8cm !important;
    border-radius: 20px !important;
  }
  .heading-level-1 {
    font-size: 1.9rem !important;
  }

  .heading-level-2 {
    font-size: 1.45rem !important;
  }

  .heading-level-3 {
    font-size: 1.2rem !important;
  }

  .heading-level-4 {
    font-size: 0.9rem !important;
  }

  .text-level-1 {
    font-size: 1.3rem !important;
  }

  .text-level-2 {
    font-size: 1.2rem !important;
  }

  .text-level-3 {
    font-size: 1rem !important;
  }

  .text-level-4 {
    font-size: 0.94rem !important;
  }

  .text-level-5 {
    font-size: 0.87rem !important;
  }

  .text-level-6 {
    font-size: 0.72rem !important;
  }

  .text-level-7 {
    font-size: 0.7rem !important;
  }
}
@media only screen and (max-width: 400px) {
  .xs-fw-700 {
    font-weight: 700 !important;
  }
}
@media only screen and (max-width: 350px) {
  .swiper.square-swiper.artwork-swipper,
  .swiper.square-swiper {
    width: 7cm !important;
    height: 6.8cm !important;
    border-radius: 20px !important;
  }
}


.dropdown_container {
  position: relative;
  display: inline-block;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: -300%;
  width: 100px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: #585854 !important;
  width: 200px;
}

.dropdown_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown_menu ul li {
  padding: 8px 12px;
  color: var(--yellow);
}
.dropdown_menu ul li:nth-child(1) {
  border-bottom: 1px solid yellow;
}
.dropdown_menu ul li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown_menu ul li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dropdown_menu ul li:hover {
  background-color: var(--yellow);
  color: var(--second);
  cursor: pointer;
}