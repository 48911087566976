@font-face {
  font-family: formular;
  src: url('./assets/fonts/Formular-Regular/Formular-Regular.ttf')
    format('truetype');
}

:root {
  --yellow: #ffff00;
  --second: #36373f;
  --purple: #7b61ff;
  --indent-yellow: #9f9f0a;
  --success: #19874f;
  --text-shadow: 0 0 1px var(--second);
  --box-shadow: 0 0 5px var(--second);
  --swiper-theme-color: #ffff00 !important;
  --docsearch-hit-active-color: #ffff00 !important;
  --docsearch-primary-color: #ffff00 !important;
  --docsearch-searchbox-shadow: inset 0 0 0 2px #ffff00 !important;
  --font-formular: 'Pragmatica', arial !important;
  --alert-message-clr: #dc4c64;
  --alert-message-clr2: #f50057;
  --swiper-theme-color: # !important;
}
.avaShadHov {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.avaShadHov:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.35);
}

.countShadHov {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.countShadHov:hover {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}

.eer-flex {
  display: flex;
  justify-content: start;
  align-items: center;
  /* width: 100%; */
  margin-right: auto;
  min-width: fit-content;
}
.eer-flex button {
  /* margin-right: auto; */
  min-width: fit-content;
}
.eer-flex .error {
  padding-bottom: 0;
  padding-left: 2rem;
}
.eer-flex .error .error-span {
  /* text-align: right; */
  min-width: fit-content;
  text-overflow: nowrap;
  white-space: nowrap;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 0;
  /* line-height: 0; */
}

.error {
  color: var(--alert-message-clr2) !important;
  font-size: 1rem;
}

*,
body {
  font-family: var(--font-formular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-formular) !important;
}

.css-mm42gz-MuiButtonBase-root-MuiFab-root {
  height: 44px !important;
  width: 44px !important;
  min-width: 36px !important;
  min-height: 36px !important;
}
.setting-icon {
  height: 2.5rem;
  width: 2.25rem;
}
.dash-icon {
  height: 3.5rem;
  width: 3.5rem;
  min-height: 100%;
  max-width: 100%;
}

#merge-previwer {
  z-index: -1;
}
.canvas-container {
  border-radius: 0.5rem;
  z-index: 999 !important;
}

.btn-preview {
  border-radius: 0.5rem;
}

.iconify {
  position: relative;
}
.hover-helper {
  background-color: #7f7f79;
  position: absolute;
  bottom: -25px;
  left: 10px;
  box-shadow: rgba(255, 255, 0, 0.3) 0px 2px 8px 0px;
  border-radius: 0.4rem;
  color: var(--yellow);
  width: 100%;
  min-width: 100px;
  font-size: small;
  z-index: 99999999999999999 !important;
  padding: 0.05rem;
  text-transform: capitalize;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: var(--indent-yellow) !important;
}

.main-title {
  font-size: 2.25rem;
  line-height: 1.5;
  letter-spacing: 2px;
}
.fw_600 {
  font-weight: 600 !important;
}
.fw_700 {
  font-weight: 700 !important;
}
.fw_500 {
  font-weight: 500 !important;
}

.fw_bolder {
  font-weight: 800 !important;
}

.btn-fs {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.page-title {
  padding-top: 10px !important;
  padding-bottom: 10px;
  margin: 0;
}
.page-title-fixed {
  box-shadow: rgba(255, 255, 255, 0.15) 1px 1px 1px;
}
.card {
  width: 100%;
}

body {
  color: white !important;
}
/* utility calss start here */
.theme-bg {
  background-color: var(--yellow) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.text-dark {
  color: #191b23 !important;
}

.bg-yellow {
  background-color: var(--yellow) !important;
}

.theme-text-color,
span.theme-text-color:not(.badge),
.theme-dark span.theme-text-color:not(.badge),
.theme-dark .theme-text-color {
  color: var(--yellow) !important;
}

.theme-text-color .selected-text,
.selected-text span.theme-text-color:not(.badge),
.theme-dark .selected-text span.theme-text-color:not(.badge),
.theme-dark .theme-text-color .selected-text {
  color: var(--yellow) !important;
}
.main-heading {
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 36px;
  font-size: 2rem;
}

.xs-px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.text-p {
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 1rem;
}

.btn {
  border-radius: 10px !important;
}

.fs-1_5rem {
  font-size: 1.5rem !important;
}

.fs-1_25rem {
  font-size: 1.25rem !important;
}

.butn {
  color: var(--yellow);
  font-size: 1.5rem !important;
  padding: 1.1rem 2rem;
  background-color: var(--second) !important;
  border: 2px solid var(--second) !important;
  outline: none !important;
  border-radius: 1rem !important;
  box-shadow: 0 0 8px var(--second);
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  line-height: 1.1;
  font-family: var(--font-formular);
  letter-spacing: 2px;
  /*  height: 64px; */
}
.butn:disabled {
  opacity: 0.75;
}
.butn-outlined {
  background-color: transparent !important;
  color: var(--transparent) !important;
  box-shadow: 0 0 6px var(--second) !important;
  border: 2px solid var(--second) !important;
}

.butn-outlined:hover {
  background-color: var(--second) !important;
  color: var(--yellow) !important;
  box-shadow: 0 0 5px 2px var(--second);
}

.butn-lg-primary {
  font-size: 1.5rem !important;
  padding: 1.1rem 2rem;
  border-radius: 1rem;
  font-weight: 600;
  box-shadow: 0 0 8px var(--yellow);
  letter-spacing: 1px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  line-height: 1.1;
}
.cursor-pointer {
  cursor: pointer;
}
.butn-lg-primary {
  background-color: var(--yellow) !important;
  color: var(--second);
}

.butn:hover {
  box-shadow: 0 0 5px 2px var(--second) !important;
}
.butn-lg-primary:hover {
  box-shadow: 0 0 5px 2px var(--yellow) !important;
}

.butn-primary {
  background-color: var(--yellow) !important;
  color: var(--second) !important;
  padding: 1.1rem 1.3rem !important;
  font-size: 1.25rem !important;
}

.bg-dark {
  background-color: var(--second);
}
.bg-black {
  background-color: #191b23;
}

.card {
  background-color: var(--second) !important;
  border-radius: 1rem !important;
  border: 1px solid var(--second) !important;
  height: 100%;
  box-shadow: 0 0 8px var(--second);
}
.card:hover {
  box-shadow: 2px 2px 24px var(--second), -2px -2px 24px var(--second);
  z-index: 99;
}
/* .col {
  flex: 0 0 25% !important;
} */
.card .card-img-top {
  /* height: 23.33vw !important; */
}

.card button.love-butn {
  background-color: var(--purple) !important ;
  box-shadow: none !important;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  text-shadow: 0 0 3px 1px var(--purple) !important;
  padding: 0.65rem 1.5rem !important;
}
.card button.love-butn,
.card button.love-butn span:not(.badge),
.theme-dark .card button.love-butn span:not(.badge) {
  color: var(--yellow) !important;
  text-shadow: 1px 1px -1px var(--indent-yellow);
}
.card button.love-butn.liked {
  color: var(--alert-message-clr2) !important;
}

.card button.love-butn:hover {
  box-shadow: 0 0 5px 1px var(--purple) !important;
}

.adjust-btn {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7) !important;
  color: rgba(255, 255, 0, 0.8) !important;
  padding: 0.9rem 1.5rem !important;
}
.swiping-item {
  background: transparent !important;
  height: 70vh !important;
  object-fit: cover;
}
.swiping-item img {
  border-radius: 1rem;
}

img.wallet-logo-dimension {
  width: 25vw !important;
  height: 25vw !important;
}

.swipper-item.swipeWallet {
  margin-top: auto !important;
  margin-bottom: auto !important;
  background-color: var(--second) !important;
}

.swipper-item.swipeWallet .wallet_Text h4,
.swipper-item.swipeWallet .wallet_Text span {
  color: var(--yellow) !important;
}

.swipper-item.swipeWallet.active {
  border: 4px solid var(--yellow);
  box-shadow: 1px 1px 2px 1px var(--yellow), -1px -1px 2px 1px var(--yellow);
}

@media only screen and (max-width: 612px) {
  .adjust-btn {
    top: 30%;
    padding: 0.5rem 1.5rem !important;
    font-size: 1.2rem !important;
  }
  .swiping-item {
    height: 60vh !important;
  }
  .swipper-item .swipper-title {
    line-height: 1.2 !important;
  }
  .main-title {
    font-size: 32px;
    line-height: 1.2;
  }
  .artwork-swipper .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 1.5rem !important;
  }
  .artwork-swipper .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 1.5rem !important;
  }
  .swipper-item.swipeWallet .wallet_Text h4 {
    font-size: 16px;
  }
  .swipper-item.swipeWallet .wallet_Text span {
    font-size: 16px;
  }
}
.custom-rounded {
  border-radius: 1rem !important;
}

@media only screen and (max-width: 375px) {
  .adjust-btn {
    padding: 0.9rem 1.5rem !important;
    font-size: 1rem !important;
  }
  .main-title {
    font-size: 26px;
    line-height: 1.2;
  }
  .swiper-slide {
    font-size: 14px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .bg-heading {
    font-size: 1rem !important;
  }
  .color-plate {
    font-size: 1.1rem;
  }
  .resize-font-sm {
    font-size: 16px !important;
  }
  .butn {
    height: 44px;
  }
}

.card button.download-butn {
  background-color: var(--yellow) !important ;
  box-shadow: none !important;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  text-shadow: 0 0 3px 1px var(--purple) !important;
  padding: 0.4rem 0;
  color: var(--purple);
}
.card button.download-butn img {
  width: 3.5rem;
  height: 2rem;
}

.card button.download-butn:hover {
  box-shadow: 0 0 5px 1px var(--yellow) !important;
}

.card .card-body .card-title {
  color: var(--yellow);
  text-transform: normal;
  font-size: 1.3rem;
  letter-spacing: 1px;
  line-height: 24px;
  font-weight: normal !important;
}

.card .card-body {
  overflow: hidden;
  padding: 0.85rem 0.85rem 0rem 0.85rem !important;
}

.card .card-body .profile-img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  border: 1px solid var(--second);
  box-shadow: 0 0 5px var(--second);
}

.card-text {
  color: var(--yellow);
}

.card-text span.hash-tag:not(.badge),
.card-text span.hash-tag:not(.badge),
.card-text span.hash-tag .theme-dark {
  color: var(--yellow) !important;
}

.card-text span.hash-tag {
  color: var(--yellow) !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
}

.card-text span.hash-tag.tag-shag {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.3rem !important;
}
.card-text span.hash-tag.hash-date {
  font-size: 0.85rem !important;
}

@media only screen and (max-width: 412px) {
  .fx-sh {
    line-height: 1 !important;
  }

  /* .artwork-swipper .swiper-button-prev,
  .artwork-swipper .swiper-button-next {
    display: none;
  } */
}

/* utlitly class ends here */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide .wallet_Text {
  text-align: start;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-rounded {
  border-radius: 30px;
}

.swiper-slide div .title,
.swiper-slide div .subtitle {
  background-color: transparent;
}

.description {
  left: 5px;
  right: 5px;
  bottom: 30px;
  border-radius: 15px;
  padding: 5px 0px;
  text-align: start;
  text-transform: capitalize;
}
.swiper-slide .title,
.swiper-slide .subtitle {
  color: var(--yellow);
  font-weight: 700;
  letter-spacing: 1.2px;
  text-shadow: var(--second) 1px 2px 2px;
}

.card .card-img-top {
  /* min-width: 100% !important;
  height: 18.4vw !important; */
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.tab {
  text-shadow: var(--text-shadow);
  letter-spacing: 2px !important;
}

.search-form {
  position: relative;
}
.search-form i {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  margin-top: auto !important;
  margin-bottom: auto !important;
  color: var(--yellow);
  font-size: 1.5rem !important;
  text-shadow: 0 0 2px var(--yellow);
}
.search-form input.search-input {
  color: var(--yellow) !important;
  border: 2px solid var(--yellow) !important;
  border-radius: 1rem !important;
  text-transform: capitalize !important;
  font-size: 1.5rem !important;
}
.search-form input#search-input:focus {
  box-shadow: 0 0 10px 2px var(--second) !important;
  border: var(--second) !important;
  outline-color: var(--yellow) !important;
}
.search-form input.search-input::placeholder {
  color: var(--yellow) !important;
  font-size: 1.5rem !important;
}
.card-items {
  width: 50%;
  border: 1px solid var(--second);
}

.abc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.choose-wallet-h4 span.text-purple,
.choose-wallet-h4 span.text-purple:not(.badge),
.theme-dark .choose-wallet-h4 span.text-purple:not(.badge) {
  color: var(--purple) !important;
}
.wallet-btn {
  padding: 0.9rem 2.5rem;
  justify-content: start;
  text-align: start;
  font-family: var(--font-formular);
  height: 100%;
}
.overflow-y-auto {
  overflow-y: auto;
}
.wallet-btn .wallet-logo {
  width: 75px;
  height: 75px;
  background-color: #191b23;
  padding: 12px;
  border-radius: 1rem;
  box-shadow: 0 0 3px 2px var(--second);
}
.modal-background {
  background: #191b23 !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-header .modal-title,
.modal-header .modal-butn,
.text-field span:not(.badge) {
  color: var(--second) !important;
}

.modal-header .modal-title,
.modal-header .modal-butn {
  font-size: 2.5rem !important;
  font-weight: 500 !important;
}

.text-field {
  width: 100% !important;
  outline: 2px solid var(--second) !important;
  border-radius: 5px;
}

.text-field label {
  background-color: var(--yellow) !important;
  font-weight: 500 !important;
  color: #888686 !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-field input:not([type='file']) {
  color: #888686 !important;
}

.upload-profile-btn {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.2rem;
  color: var(--second);
  font-weight: normal;
  /* align-items: center; */
  align-items: start;
}

.show-onhover {
  display: none !important;
}
.showRemoveIconOnhover:hover .show-onhover {
  display: block !important;
}

.delete-butn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
}
.mui-modal-input input::placeholder {
  color: var(--second) !important;
}

.upload-profile-btn i {
  font-size: 3rem !important;
}

.fa-we-400 {
  font-weight: 400;
}

.butn.btn-f-small {
  font-size: 1.3rem !important;
}

.dash-counter i {
  font-size: 2.5rem !important;
  color: var(--purple);
}

.dash-counter h3 {
  font-size: 1rem !important;
  letter-spacing: 1px !important;
  color: var(--purple);
  font-weight: 500;
}

.dash-counter span {
  font-size: 2rem !important;
  letter-spacing: 1px !important;
  color: var(--yellow) !important;
  font-weight: 600;
}

@media only screen and (max-width: 558px) {
  .dash-counter {
    padding-right: 5px;
  }

  .dash-counter span {
    font-size: 1.5rem !important;
  }
  .dash-counter i {
    font-size: 1.75rem !important;
  }
  .fs-1_5rem,
  .fs-1_25rem {
    font-size: 1rem !important;
  }
  .sm-hidden {
    display: none;
  }
  .eer-flex .error .error-span {
    text-overflow: wrap;
    white-space: normal;
  }
}

.formating .format-heading {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px !important;
}

.formating,
.formating label {
  background: transparent !important;
}

.formating .form-check label {
  font-size: 1.2rem;
  padding-left: 5px;
}
.formating .fs-t.form-check input:not([type='file']) {
  font-size: 18px !important;
}
.formating .fs-t.form-check input:checked:not([type='file']) {
  font-size: 18px !important;
}
.formating .fs-t.form-check input:focus:not([type='file']),
.formating .fs-t.form-check input:not([type='file']) {
  border-color: var(--yellow) !important;
}

.form-check-input {
  margin-top: 0.1rem !important;
}

.form-check {
  display: flex !important;
  justify-content: space-between !important;
}

.formating .form-check input:not([type='file']) {
  background-color: transparent !important;
  border-color: var(--yellow) !important;
  font-size: 14px !important;
}

.select-icon {
  position: absolute;
  right: 0.85rem;
  top: 17.5%;
}
.select-icon button {
  font-size: 1.25rem;
  transform: rotate(270deg);
}

.formating .form-check input:not([type='file']):checked {
  /* background-color: var(--yellow) !important; */
  color: var(--yellow) !important;
}
.form-check-input[type='radio'] {
  border: 1px solid var(--yellow);
}
.form-check-input:checked[type='radio'] {
  background-image: url('./assets/images/dot.svg') !important;
  border: 1px solid var(--yellow) !important;
  background-position: center center;
}

.nft-swipper {
  background-color: var(--yellow) !important;
  padding: 0.5rem !important;
  border-radius: 1rem;
}

.nft-swipper img {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.swipper-wallet {
  object-fit: contain !important;
  height: 40vh !important;
}
.swipper-shadow .swipper-item {
  box-shadow: var(--box-shadow);
}

/* temporary css */
.css-gcwvw8-MuiAutocomplete-root .MuiOutlinedInput-root {
  background-color: yellow;
}

li.MuiImageListItem-root.MuiImageListItem-standard.cursor-pointer.active-artwork.css-ta95xa-MuiImageListItem-root {
  border: 4px solid var(--purple);
}

.wallet-swipper {
  width: 40% !important;
  height: 50vh !important;
}

.swiper.walletSwiper {
  width: 50% !important;
  height: fit-content !important;
}

.nft-prevew-dimensions {
  width: 40vw;
  height: 40vw;
}

.col.col-w {
  min-width: 250px;
  min-height: 250px;
}

@media only screen and (max-width: 1440px) {
  .swiper.mySwiper.artwork-swipper,
  .swiper.mySwiper {
    width: 80% !important;
  }

  /*  .col {
    flex: 0 0 33% !important;
  } */
  .card .card-img-top {
    /* height: 31.66vw !important; */
  }
  .nft-prevew-dimensions {
    width: 50vw;
    height: 50vw;
  }
}

.nft-swiper-text {
  padding: 18px 6px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.2rem !important;
  color: var(--yellow);
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  border-radius: 100%;
}
.swiper-button-prev:after {
  padding-left: 0.7rem;
  padding-right: 0.9rem;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 1.25rem !important;
}
.artwork-swipper .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 2rem !important;
}
.swiper-button-next:after {
  padding-left: 0.9rem;
  padding-right: 0.7rem;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 1.25rem !important;
}

.artwork-swipper .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 2rem !important;
}

.nft-swipper .nft-swiper-text {
  background-color: transparent !important;
  padding: 1rem !important;
}

.nft-swipper .nft-swiper-text h4,
.nft-swipper .nft-swiper-text span {
  padding: 0 !important;
  text-align: start !important;
  color: var(--second) !important;
}

.nft-swipper .nft-swiper-text h4 {
  text-transform: capitalize;
}

.formating .formating-content {
  font-size: 1rem;
  letter-spacing: 1px;
  color: var(--indent-yellow);
}

.wallet-select-field {
  outline: 2px solid var(--yellow) !important;
  border-radius: 5px;
}

.autocomplete-select-field {
  outline: 1px solid var(--yellow) !important;
  border-radius: 5px;
}

.wallet-select-field:focus {
  outline: none !important;
}

.autocomplete-select-field:focus {
  outline: none !important;
}
.wallet-select-field label,
.wallet-select-field label .input-label {
  color: var(--yellow) !important;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #191b23 !important;
}

.autocomplete-select-field label,
.autocomplete-select-field label.input-label {
  color: var(--yellow) !important;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #191b23 !important;
}

.wallet-select-field .css-a88p61-MuiInputBase-root-MuiInput-root {
  color: var(--yellow) !important;
}

.autocomplete-select-field css-a88p61-MuiInputBase-root-MuiInput-root {
  color: var(--yellow) !important;
}

.selected-text {
  color: var(--yellow) !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: transparent !important;
  border: 1px solid var(--second) !important;
}

.selected-color {
  width: 50px;
  height: 3rem;
  border: 1px solid var(--second) !important;
  border-radius: 0.4rem;
}

.css-19kzrtu {
  padding: 0 !important;
}
.color-picker {
  height: 2.75rem !important;
}
.hue-horizontal {
  border-radius: 0.4rem !important;
}

.hue-horizontal > div > div {
  display: flex !important;
  align-items: center !important;
  height: 3rem !important;
  border-radius: 0 !important;
  box-shadow: var(--box-shadow) !important;
  border-radius: 3px !important;
}

/* input tags */

.tags-input-container {
  /* border: 2px solid #000; */
  padding: 0.5em;
  border-radius: 3px;
  width: min(80vw, 600px);
  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: var(--yellow);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
}

.tag-item span.text {
  color: var(--second) !important;
}

.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}

.pagenation .pagenation-item {
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 2px solid var(--yellow);
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.pagenation .pagenation-item.active {
  background-color: var(--yellow) !important;
}

.nfts-image-cards .nft-image-card {
  height: 300px;
  width: 300px;
  border-radius: 1.15rem;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.nfts-image-cards .nft-image-card.active {
  background-color: #ffff00;
  /* border: 3px solid #ffff00; */
}

.nfts-image-cards .nft-image-card img {
  border-radius: 1rem;
  border: none;
}

.swipper-item {
  background-color: var(--yellow) !important;
  padding: 10px !important;
  border-radius: 1rem;
}

.swipper-item .swipper-title {
  color: var(--second) !important;
  font-size: 2.25rem !important;
  letter-spacing: 1px;
  line-height: 1.5 !important;
}

.swipper-item .butn {
  padding: 1.1rem 1.75rem !important;
}

.text-content {
  color: var(--second) !important;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 36px;
}

.swiper-slide-transparent {
  background: transparent !important;
}
.login-form-link,
.login-form-link > a {
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  color: var(--second);
  text-transform: capitalize;
  text-shadow: 1px 1px var(--indent-yellow);
}
.swal2-container.swal2-top,
.swal2-container.swal2-center,
.swal2-container.swal2-bottom {
  z-index: 99999;
}

span:not(.badge),
.theme-dark span:not(.badge),
.theme-dark {
  color: var(--second) !important;
}

.nft-card-w {
  width: 20%;
}
.nfts-image-cards .nft-image-card.deactive {
  background-color: transparent;
}

.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  padding-bottom: 5px;
  top: 90%;
}
.theme-dark input:not([type='file']),
.theme-dark select,
.theme-dark textarea {
  color: var(--yellow);
}
.description-text .MuiOutlinedInput-notchedOutline {
  color: var(--second);
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root input {
  height: 80% !important;
  vertical-align: text-top;
  font-size: 1.2rem !important;

  opacity: 1;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root input::placeholder {
  font-size: 1.2rem !important;
  opacity: 1;
}
.description-text
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root
  input::placeholder {
  color: var(--yellow) !important;
  margin-top: 0 !important;
  position: absolute !important;
  top: 1rem !important;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root input:focus {
  border-color: #ffff00 !important;
}

.descript-style .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  height: 180px;
}

@media only screen and (max-width: 578px) {
  /* .nfts-image-cards .nft-image-card {
    height: 250px;
    width: 250px;
  } */
  h1 {
    font-size: x-large !important;
  }
  h3 {
    font-size: medium !important;
  }
  h5 {
    font-size: medium !important;
  }
  .swal2-title {
    font-size: 1.3em !important;
  }
  .swal2-html-container {
    font-size: 0.9em !important;
  }
  .swal2-popup {
    width: auto !important;
  }
  .swal2-icon {
    margin-top: 16px !important;
    margin-bottom: 0px !important;
  }
  .swal2-actions {
    margin-top: 0px !important;
    font-size: 0.9em !important;
  }
  .xs-px-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .css-19kzrtu {
    padding: 0 !important;
  }
  .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    padding-bottom: 4px;
  }
  .wallet-btn {
    padding: 0.9rem 1rem;
    text-overflow: ellipsis;
  }
  .swipper-item .swipper-title {
    font-size: 2rem !important;
  }
  .sm-d-block {
    display: block !important;
  }
  .sm-d-none {
    display: none !important;
  }
}

@media only screen and (max-width: 474px) {
  .wallet-btn {
    padding: 0.65rem 0.75rem !important;
    font-size: 1.2rem !important;
  }
  .wallet-btn .wallet-logo {
    width: 60px;
    height: 60px;
  }
  .butn {
    font-size: 1.2rem !important;
  }
}

.css-1wf8b0h-MuiTabs-flexContainer {
  justify-content: space-between;
}

@media only screen and (max-width: 425px) {
  .address_item {
    padding-left: 1rem !important;
  }
  h1 {
    font-size: large !important;
  }
  h3 {
    font-size: medium !important;
  }
  h5 {
    font-size: small !important;
  }
  section {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .nfts-image-cards {
    flex-direction: column !important;
  }
  /* .nfts-image-cards .nft-image-card {
    width: 100% !important;
    height: 250px;
  } */
  .nfts-image-cards .nft-image-card {
    height: 250px;
    width: 250px;
  }
  .swipper-item .swipper-title {
    font-size: 1.4rem !important;
  }
  .nft-card-w {
    width: 100%;
  }

  .wallet-btn {
    padding: 0.9rem 0.1rem;
    font-size: 1rem !important;
  }

  .butn-primary {
    padding: 1rem 1.1rem;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
}
/* 
  admin dashboard styling
*/
.admin-nav {
  padding: 0.65rem 1.5rem;
  background: var(--yellow);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mui-nav-icon {
  color: var(--second);
}
.mui-nav-icon,
.mui-nav-icon-user {
  font-size: 2rem !important;
}
.mui-nav-icon-user {
  color: var(--yellow);
}

.a-nav-dropd {
  background-color: var(--second);
  position: absolute;
  padding: 0.35rem;
  border-radius: 6px;
  max-width: 100px;
  min-width: 75px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.a-nav-li {
  color: var(--yellow);
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.15rem;
  padding-right: 1.75rem;
  padding-top: 0.15rem;
  padding-bottom: 0.2rem;
  cursor: pointer;
}

.a-mui-drop-down
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: var(--second) !important;
  color: var(--yellow);
}

.a-mui-drop-down
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  ul
  li {
  padding-right: 2.5rem;
}

.a-mui-drop-down
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  ul
  li:hover {
  background-color: var(--yellow);
  color: var(--second) !important;
}

.mui-icon-btn {
  max-width: 40px !important;
  padding: 0.5rem;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--yellow) !important;
  color: var(--second) !important;
}
.active li.MuiListItem-root {
  background-color: var(--second) !important;
  color: var(--yellow) !important;
}

.active
  li.MuiListItem-root.MuiListItem-gutters.css-1nxmd3h-MuiListItem-root
  span:not(.badge),
.active
  li.MuiListItem-root.MuiListItem-gutters.css-1nxmd3h-MuiListItem-root
  .theme-dark
  span:not(.badge) {
  color: var(--yellow) !important;
}

.active
  li.MuiListItem-root.MuiListItem-gutters.css-1nxmd3h-MuiListItem-root
  svg {
  color: var(--yellow) !important;
}

.css-1ab2xsx {
  background-color: var(--yellow) !important;
  color: var(--second) !important;
}
.css-1f8bwsm {
  min-width: 1.5em !important;
  padding-right: 1px;
}
.mui-side-text span {
  font-weight: bold;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

.admin-footer {
  text-align: center;
  color: var(--yellow);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
  padding: 1rem 0.5rem;
  font-size: large;
  background-color: #1b1d21;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px inset;
}

.admin-dash-container {
  padding: 1rem;
}

.ad-dash-main-heading {
  font-size: 2.5rem;
  color: var(--yellow) !important;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
  font-weight: bolder;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
}
.btn-w {
  width: 50%;
}
.address_item {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  padding-right: 1.5rem;
  padding-left: 0;
}
.address_item .address-cards {
  background-color: var(--yellow);
  color: var(--second);
  padding: 1.5rem 1rem;
  border-radius: 0.25rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 8px rgba(255, 255, 0, 0.75);
}
.address_item .address-cards:hover {
  border: 1px solid rgba(0, 0, 0, 0.45);
  box-shadow: 0 0 10px rgba(255, 255, 0, 0.85);
}
.address_item .address-cards h1,
.address_item .address-cards h2,
.address_item .address-cards h3,
.address_item .address-cards h4,
.address_item .address-cards h5,
.address_item .address-cards h6 {
  color: var(--second) !important;
}
.address_item .address-cards p {
  margin: 0 !important;
  /* overflow-wrap: break-word; */
  overflow: hidden;
}
.address_item .address-cards .address-btn {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
}
.address_item .address-cards .address-btn .icon-mui-btn button {
  border: 1px solid rgba(255, 255, 0, 0.1);
  box-shadow: 0 0 12px rgba(255, 255, 0, 0.25);
}
@media only screen and (min-width: 2000px) {
  /* .col {
    flex: 0 0 20% !important;
  } */
  h4.userSwipHeading {
    font-size: 2rem !important;
  }
  span.userSwipWallet {
    font-size: 1.75rem !important;
    line-height: 1.5;
  }
}

@media only screen and (min-width: 1800px) {
  .swiper.mySwiper.artwork-swipper,
  .swiper.mySwiper {
    width: 70% !important;
  }
  .card .card-img-top {
    /* height: 18.33vw !important; */
  }
  .address_item {
    width: 33%;
  }
  .swiper.walletSwiper {
    width: 60% !important;
  }
}
@media only screen and (min-width: 2200px) {
  .address_item {
    width: 33%;
  }
}
@media only screen and (min-width: 2600px) {
  .address_item {
    width: 25%;
  }
}
@media only screen and (max-width: 1800px) {
  .nft-card-w {
    width: 25%;
  }
  .address_item {
    width: 50%;
  }
}
@media only screen and (max-width: 1024px) {
  .nft-card-w {
    width: 33%;
  }
  .wallet-swipper {
    width: 50% !important;
  }
  .address_item {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .swiper.walletSwiper {
    width: 60% !important;
  }
}
@media only screen and (max-width: 885px) {
  .swiper.walletSwiper {
    width: 70% !important;
  }
  /* .col {
    flex: 0 0 50% !important;
  } */
}
@media only screen and (max-width: 802px) {
  /* .card {
    width: 47vw !important;
  } */
}
@media only screen and (max-width: 768px) {
  .swiper.walletSwiper {
    width: 85% !important;
  }
  .nft-card-w {
    width: 50%;
  }
  .wallet-swipper {
    width: 75% !important;
  }

  .card .card-img-top {
    /* height: 48.33vw !important; */
  }
  .address_item {
    width: 100%;
  }
  .nft-prevew-dimensions {
    width: 60vw;
    height: 60vw;
  }
  /* .col {
    flex: 0 0 100% !important;
  } */
  /* .col.col-w .card {
    width: 75vw !important;
    margin: auto;
  } */

  /* .col {
    flex: 0 0 50% !important;
  } */
  .swiping-item {
    height: 70vw !important;
  }
}
@media only screen and (max-width: 732px) {
  .eer-flex {
    flex-direction: column;
  }
  .eer-flex .error {
    padding-left: 0;
  }
}
@media only screen and (max-width: 602px) {
  .flex-btn {
    flex-direction: column;
  }

  .swiper.walletSwiper {
    width: 100% !important;
  }
  .wallet-swipper {
    width: 100% !important;
  }
  .btn-w {
    width: 100%;
  }
  .xs-pe-2,
  .xs-ps-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  /* .card {
    width: 100% !important;
  } */
  .nft-prevew-dimensions {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 562px) {
  .ad-dash-main-heading {
    font-size: 2rem;
  }
  .nft-card-w {
    width: 50%;
  }
  /* .col {
    flex: 0 0 100% !important;
  } */

  .card .card-img-top {
    /* height: 98.33vw !important; */
  }
}
@media only screen and (max-width: 430px) {
  .flex-col-430px {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center !important;
    text-overflow: ellipsis;
  }
  .flex-col-430px .form-check {
    padding-top: 5px;
  }
  .nft-card-w {
    width: 100%;
  }
  /* .nfts-image-cards .nft-image-card {
    height: 250px;
  } */
}
@media only screen and (max-width: 425px) {
  .modal-header .modal-title {
    font-size: 2rem !important;
  }
  .smW {
    width: 100% !important;
  }
  .ad-dash-main-heading {
    font-size: 1.75rem;
  }
  .search-form input.search-input,
  .search-form input.search-input::placeholder {
    font-size: 1.2rem !important;
  }
  .search-form i {
    font-size: 1.05rem !important;
  }
  .modal-p {
    font-size: 1rem !important;
  }
  .swiping-item {
    /* height: 50vh !important; */
    height: 50vw !important;
  }
  .admin-footer {
    text-align: center;
    font-size: small;
  }
  .butn.btn-f-small {
    font-size: 1rem !important;
  }
  .main-heading {
    font-size: 1.5rem;
  }
  .butn-primary {
    padding: 0.85rem 1.35rem !important;
    font-size: 1rem;
    letter-spacing: 0.75px;
  }
  .css-1km1ehz,
  .css-r8u8y9,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 1 !important;
  }
  .css-1km1ehz {
    min-height: 30px !important;
  }
  .description {
    bottom: 0;
  }
}

.css-14cicra-MuiDataGrid-root {
  font-size: 1.5rem !important;
  font-weight: 900 !important;
}
.MuiDataGrid-row {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}
.css-17jjc08-MuiDataGrid-footerContainer {
  display: none !important;
}

.mui-grid-btn {
  background-color: var(--second) !important;
  color: var(--yellow) !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: 2px solid var(--second) !important;
}

.mui-grid-btn-primary {
  background-color: var(--yellow) !important;
  color: var(--second) !important;
  border: 2px solid var(--second) !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.mui-grid-btn-primary:disabled {
  opacity: 0.75;
}

.status-select-field label,
.status-select-field label .input-label {
  color: var(--second) !important;
  padding-left: 1rem;
  padding-right: 1rem;
  background: var(--yellow) !important;
}

.selected-text {
  color: var(--second) !important;
}

.swal2-title {
  color: var(--second) !important;
  line-height: 1.2;
  letter-spacing: 1px;
  font-family: var(--font-formular) !important;
}

.mui-pagenate-icon {
  color: var(--yellow);
  font-size: 1.6rem !important;
  padding: 0;
  margin: 0;
}

.mui-icon-btn:disabled > .mui-pagenate-icon {
  /* opacity: 0.75; */
  color: var(--indent-yellow);
}

.color-plate {
  /* width: 6rem; */
  height: 3rem;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin: auto !important;
}

.bg-heading {
  color: var(--second) !important;
  font-size: 1.5rem;
}

.css-5u56kq-MuiAvatar-root {
  color: var(--yellow) !important;
}

.modal-swiper {
  background-color: var(--second) !important;
  color: var(--yellow);
}
.modal-swiper-text {
  background: var(--second) !important;
  padding-bottom: 1rem;
}
.modal-swiper-text h4,
.modal-swiper-text span.theme-text-clr {
  color: var(--yellow) !important ;
  letter-spacing: 1px;
}

.signin-form {
  border: 1px solid var(--yellow);
  border-radius: 1rem;
}

.min-h-100vh {
  min-height: 100vh;
}

.fx-sh {
  letter-spacing: 1px;
  line-height: 1.5;
  text-shadow: 1px 1px 1px var(--indent-yellow);
}
.txt-underline {
  text-decoration: underline !important;
}
.fs-lg {
  font-size: large !important;
}
.xs-pe-2 {
  padding-right: 0.5rem;
}
.xs-ps-2 {
  padding-left: 0.5rem;
}

@media only screen and (max-width: 472px) {
  .sm-flex-d {
    flex-direction: column;
  }
  .butn {
    font-size: 1rem !important;
    padding: 0.9rem 2rem;
    line-height: 1.25;
  }
  .butn-lg-primary {
    padding: 0.8rem;
  }
  .swal2-title {
    line-height: 1.1;
  }
  .col.col-w .card img {
    width: 100% !important;
    height: 100% !important;
  }
}
@media only screen and (max-width: 340px) {
  .butn-lg-primary {
    padding: 1rem;
    font-size: 1.2rem !important;
  } /* 
  .butn {
    padding: 0.9rem 2rem;
  } */
}
@media only screen and (max-width: 332px) {
  .color-plate {
    font-size: 1rem;
  }
  .ad-dash-main-heading {
    font-size: 1.5rem;
  }

  .dash-counter {
    width: 100% !important;
    padding-bottom: 10px;
  }
  .modal-header .fw_bolder {
    font-weight: 600;
  }
  .modal-header .modal-title {
    font-size: 1.65rem !important;
  }
  .upload-profile-btn {
    text-align: start;
  }
  .xs-flex-column {
    flex-direction: column;
  }
  .swipper-item .swipper-title {
    /* font-size: 1.75rem !important; */
    font-size: 1.25rem !important;
    line-height: 1;
  }
}
@media only screen and (max-width: 272px) {
  .xsm-d-col {
    flex-direction: column;
  }
}
.search-form .fa-magnifying-glass {
  font-weight: 550 !important;
}

.modal-header .modal-title.fw_bolder,
.modal-header .modal-butn.fw_bolder {
  font-weight: 700 !important;
}
.modal-header {
  padding: 0 !important;
}
.modal-p {
  font-size: 1.2rem;
  color: black !important;
  font-weight: normal;
  line-height: 1.2;
  padding: 0;
  margin: 0;
}
.profile-img-preview {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 1px solid var(--yellow);
  /* box-shadow: 1px 1px 1px var(--indent-yellow); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1px;
}
.profile-dark-border {
  border: 1px solid var(--yellow) !important;
  box-shadow: var(--indent-yellow) 0px 2px 8px 0px;
  padding: 0;
}

.card .crd-image-top {
  height: 320px !important;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.artwork-preview {
  width: 100%;
  height: 200px;
  border: 1px solid var(--indent-yellow);
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  background: #1b1d21;
}

.a-mui-drop-down
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  ul {
  border-radius: 8px !important;
}
.a-mui-drop-down
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border: 2px solid rgba(255, 255, 0, 0.9) !important;
  border-radius: 8px;
  box-shadow: rgba(255, 255, 0, 0.56) 0px 3px 6px,
    rgba(255, 255, 0, 0.43) 0px 3px 6px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding-left: 24px !important;
}

span.MuiFormLabel-asterisk:not(.badge),
.theme-dark span.MuiFormLabel-asterisk:not(.badge),
.theme-dark {
  color: var(--yellow) !important;
}
.mui-modal-input input:not([type='file']),
.mui-modal-input select,
.mui-modal-input textarea,
.mui-modal-input span.MuiFormLabel-asterisk:not(.badge),
.mui-modal-input .theme-dark span.MuiFormLabel-asterisk:not(.badge),
.mui-modal-input .theme-dark {
  color: var(--second) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}

input,
button,
select,
optgroup,
textarea,
.css-2s90m6-MuiAvatar-root,
.css-10hburv-MuiTypography-root,
.btn,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: var(--font-formular) !important;
}

.clr-black span.MuiFormLabel-asterisk:not(.badge),
.theme-dark .clr-black span.MuiFormLabel-asterisk:not(.badge),
.theme-dark {
  color: var(--second) !important;
}

.theme-dark .clr-black input:not([type='file']),
.theme-dark .clr-black select,
.theme-dark .clr-black textarea {
  color: var(--second) !important;
}

.error {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
}
.error > span.error-span {
  font-family: var(--font-formular);
  text-align: start;
  color: var(--alert-message-clr2) !important;
  font-weight: 500 !important;
  letter-spacing: 0.4px;
  margin-top: -5px;
  padding-bottom: 5px;
}

.error > span.light-error-clr {
  color: var(--alert-message-clr2) !important;
  font-weight: 600;
}
.swal2-popup {
  background-color: var(--yellow) !important;
}
.swal2-icon.swal2-success
  [class^='swal2-success-circular-line'][class$='left'] {
  background-color: transparent !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid var(--success) !important;
}
.swal2-icon.swal2-success [class^='swal2-success-line'],
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: var(--success) !important;
}

button.swal2-styled {
  background-color: var(--second) !important;
  color: var(--yellow) !important;
}

button.swal2-styled {
  box-shadow: 0 0 8px var(--second) !important;
  font-family: var(--font-formular) !important;
}

button.swal2-styled:hover {
  box-shadow: 0 0 5px 2px var(--second) !important;
}
.css-mm42gz-MuiButtonBase-root-MuiFab-root {
  z-index: 999 !important;
}
@media only screen and (max-width: 400px) {
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-column .dash-counter {
    width: 100% !important;
  }
}

@media only screen and (max-width: 375px) {
  /* .butn {
    font-size: 1rem !important;
    padding: 0.9rem 2rem;
    line-height: 1.25;
  } */

  .logo-content {
    font-size: 1.1rem;
  }
  .swiping-item {
    /* height: 50vh !important; */
    height: 50vw !important;
  }
  .modal-p {
    font-size: 0.75rem !important;
  }
  .dash-icon {
    width: 3.5rem !important;
    height: 3.5rem !important;
  }
  .dash-counter {
    width: 100% !important;
    height: 100% !important;
  }
  .pagenation .pagenation-item {
    width: 20px;
    height: 20px;
  }
  .wallet-swipper {
    height: 45vh !important;
  }
  .upload-profile-btn i {
    font-size: 2.5rem !important;
  }
  .error > span.error-span {
    font-size: 14px;
  }
}
@media only screen and (max-width: 320px) {
  .wallet-swipper {
    height: 36vh !important;
  }
  .nfts-image-cards .nft-image-card {
    height: 200px;
    width: 200px;
  }
  /* .logo-content {
    font-size: 0.9rem;
  } */
  .swiping-item {
    /* height: 40vh !important; */
    height: 40vw !important;
  }

  .wallet-btn {
    font-size: 0.9rem !important;
    padding: 0.5rem 0.75rem !important;
  }
  .wallet-btn .wallet-logo {
    width: 50px;
    height: 50px;
    padding: 0.5rem;
  }
  .butn-primary {
    padding: 0.65rem 1.1rem !important;
    font-size: 1rem;
    letter-spacing: 0.1px;
  }
  .butn {
    font-size: 0.95rem !important;
  }
  .resize-font-sm {
    font-size: 12px !important;
  }
}

.css-1q79v3g-MuiButtonBase-root-MuiChip-root,
input,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 1.2rem !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  font-family: var(--font-formular) !important;
  font-size: 1.2rem !important;
}
.bg-image {
  background-size: cover;
}
.twitter-btn {
  justify-content: center;
}
.icon-shadow {
  text-shadow: 0 0 3px var(--second) !important;
}
.fs-text-stroke-2px {
  -webkit-text-stroke: 2px;
}

@media only screen and (max-width: 802px) {
  .card .card-img-top {
    /* height: 275px !important; */
  }
}

@media only screen and (max-width: 372px) {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 12px 5px !important;
  }
}
.clr-dark {
  color: var(--second) !important;
}

.theme-dark .dark-auto-complete span.css-6od3lo-MuiChip-label {
  color: var(--yellow) !important;
}
.dark-auto-complete .MuiChip-deleteIcon {
  fill: var(--yellow) !important;
}
.formating,
.formating label {
  color: var(--yellow) !important;
}

.MuiAutocomplete-hasPopupIcon.css-lgv4zd-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-lgv4zd-MuiAutocomplete-root
  .MuiOutlinedInput-root:hover {
  border-color: var(--yellow) !important;
  outline-color: var(--yellow) !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: var(--yellow) !important;
}

.MuiAutocomplete-root fieldset:hover {
  border-color: var(--yellow) !important;
}

.MuiAutocomplete-root fieldset:focus {
  border-color: var(--yellow) !important;
}

.MuiAutocomplete-root fieldset {
  border-color: var(--yellow) !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--yellow) !important;
  border-color: transparent !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  color: var(--yellow) !important;
  border-color: transparent !important;
}
textarea .css-i4bv87-MuiSvgIcon-root {
  fill: var(--yellow) !important;
}

.theme-dark .text-dark input:not([type='file']),
.theme-dark .text-dark select,
.theme-dark .text-dark textarea,
.css-1e6y48t-MuiButtonBase-root-MuiButton-root.mui-grid-btn-primary {
  color: var(--second) !important;
}

.butn .button-img {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.paperlist {
  background-color: var(--yellow) !important;
}
.css-1ujsas3,
.css-177ic5c {
  color: var(--yellow) !important;
}
.css-177ic5c {
  background-color: #1b1d21 !important;
  box-shadow: 1px 1px solid var(--yellow);
}
.css-1x5jdmq.Mui-disabled {
  -webkit-text-fill-color: var(--indent-yellow) !important;
}
.mui-grid-btn:disabled {
  opacity: 0.75;
}
.MuiDataGrid-footerContainer.css-1yy0dv2 {
  display: none !important;
}

.mui-grid-btn > .butn-icon:disabled {
  fill: var(--indent-yellow) !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: var(--yellow) !important;
}
.w-75px {
  width: 75px;
}
.css-1m871ts {
  z-index: 99 !important;
}

/* .wipper */
.swiping-item.active img {
  border: 3px solid var(--yellow);
  box-shadow: rgb(255, 255, 0) 0px 2px 8px 0px;
}
.theme-dark input:not([type='submit']):not(.focus-color):focus,
.theme-dark select:focus,
.theme-dark textarea:active {
  color: var(--second);
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-1a0yc48-MuiFormControl-root,
.css-q8hpuo-MuiFormControl-root {
  border: 1px solid var(--second) !important;
  border-radius: 4px !important;
}

.theme-dark input:not([type='submit']):not(.focus-color):focus,
.theme-dark select:focus,
.theme-dark textarea:active {
  color: var(--yellow);
}
.css-177ic5c,
.css-1ujsas3 {
  color: var(--second) !important;
}

.theme-dark .mui-modal-input input:not([type='submit']):not(.focus-color):focus,
.theme-dark .mui-modal-input select:focus,
.theme-dark .mui-modal-input textarea:active {
  color: var(--second) !important;
}

.text-yellow {
  color: var(--yellow) !important;
}

.text-index-yellow {
  color: var(--indent-yellow) !important;
}

.theme-dark .clr-black input:not([type='submit']):not(.focus-color):focus,
.theme-dark .clr-black select:focus,
.theme-dark .clr-black textarea:active {
  color: var(--second) !important;
}

.css-qiwgdb {
  border: 1px solid var(--second) !important;
  border-radius: 4px !important;
}

span.MuiChip-label.MuiChip-labelMedium.css-9iedg7 {
  color: var(--yellow) !important;
}
.mui-border {
  padding: 0 !important;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 4px;
}

span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
  padding-left: 1rem;
}

.swiper-button-next {
  right: 2.5rem !important;
}

.swiper-button-prev {
  left: 2.5rem !important;
}

.pageNotFound-404 {
  border: 2px solid var(--yellow);
  width: 50vw;
  height: 30vw;
  margin: auto;
  margin-top: 20%;
  margin-bottom: 20%;
  transform: translateY(-25%);
  border-radius: 2rem;
}
.pageNotFound-404 h1 {
  font-size: 3rem;
  line-height: 1.5;
}
.pageNotFound-404 h2 {
  font-size: 2.2rem;
}
.theme-dark input.text-dark:not([type='submit']):not(.focus-color):focus,
.theme-dark select.text-dark:focus,
.theme-dark textarea.text-dark:active {
  color: var(--second);
}

.preLoaderStyling,
.preLoaderStyling2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

.preLoaderStyling2 {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0 0 0 / 0.45);
  z-index: 99999999999999;
  -webkit-tap-highlight-color: transparent;
  backdrop-filter: blur(4px);
  position: fixed;
  inset: 0px;
  top: 0;
  left: 0;
}

button:disabled,
.butn:disabled {
  opacity: 0.75 !important;
}
input#feedback:focus {
  color: var(--second) !important;
}

.text-field.feedback-field {
  border: 1px solid var(--second) !important;
  outline: none !important;
}

.text-field.text-dark label {
  color: var(--second) !important;
}

.text-field input {
  font-size: 1.2rem !important;
}

.css-204u17-MuiDataGrid-main,
.css-f3jnds-MuiDataGrid-columnHeaders {
  overflow-x: scroll !important;
}
.css-1q7ua5x .MuiDataGrid-columnHeader:focus,
.css-1q7ua5x .MuiDataGrid-cell:focus,
.css-iuxcck-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-iuxcck-MuiDataGrid-root .MuiDataGrid-cell:focus {
  /* outline: var(--swiper-theme-color) !important; */
  outline: none !important;
}

.tooltips {
  /* position: relative; */
  /* display: inline-block; */
  /* border-bottom: 1px dotted black; */
}

.tooltips .tooltipstext {
  visibility: hidden;
  /* width: 100%; */
  width: 100px;
  background-color: var(--second);
  text-align: center;
  border-radius: 0.125rem;
  padding: 0px !important;
  /* Position the tooltip */
  position: absolute;
  bottom: -17px;
  /* left: 0; */
  z-index: 9999;
  box-shadow: rgba(255, 255, 0, 0.125) 0px 1px 4px,
    rgba(255, 255, 0, 0.125) -1px -1px 4px;
  opacity: 0.88;
  font-size: 11px;
}
.tooltips span:not(.badge),
.tooltips .theme-dark span:not(.badge),
.tooltips .theme-dark {
  color: var(--yellow) !important;
}

.tooltips:hover .tooltipstext,
.tooltips:active .tooltipstext {
  visibility: visible;
}

.up-bt-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .up-bt-layout {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.swal2-icon.swal2-info {
  border-color: var(--alert-message-clr) !important;
  color: var(--alert-message-clr) !important;
}

.css-1hskriy,
.css-1ontqvh {
  width: 100% !important;
}

@media only screen and (max-width: 3000px) and (min-width: 2000px) {
  .card-text span.hash-tag {
    font-size: 1.4rem !important;
  }
  p,
  span,
  h3,
  li,
  h2 {
    font-size: 1.7rem !important;
  }
  .fs-1_25rem {
    font-size: 1.5rem !important;
  }
  .MuiDrawer-root .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 400px !important;
  }
  .MuiDrawer-root div .css-1160xiw-MuiPaper-root-MuiDrawer-paper div {
    width: auto !important;
  }
  div,
  .butn {
    font-size: 2rem !important;
  }

  .MuiFormControl-root,
  .MuiInputBase-root {
    min-height: 80px !important;
  }
  .MuiFormControl-root label,
  .MuiInputBase-root input,
  .MuiInputBase-root input::placeholder {
    font-size: 1.7rem !important;
  }
  .css-17sxhkp-MuiDataGrid-root {
    color: black !important;
  }
  .MuiDataGrid-row {
    min-height: 54px !important;
    max-height: 54px !important;
  }
  .css-i4bv87-MuiSvgIcon-root,
  .css-vubbuv {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .css-1hskriy,
  .css-1ontqvh {
    width: 500px !important;
  }

  div .swal2-icon span,
  .swal2-html-container p {
    font-size: 2rem !important;
  }
  .swal2-title {
    font-size: 3rem !important;
  }
}

@media only screen and (max-width: 578px) {
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root input,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill::placeholder,
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root::placeholder,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder,
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root input::placeholder,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill::placeholder,
  .MuiInputBase-root input::placeholder,
  .MuiInputBase-root input {
    font-size: 0.9rem !important;
  }
  .modal-butn {
    font-size: 1.5rem !important;
  } /* .MuiInputBase-root input
  {
    padding: 10px 8px !important;
  }
  .MuiInputBase-root input::placeholder   {
    padding: 0 !important;
  } */
  /* .MuiFormControl-root label{
    /* padding: 0 !important;
    font-size: 0.9rem !important;
    position: absolute !important;
    top: 0 !important;
    margin: 0 !important; */
  /* font-size: 0.9rem !important;
    margin-top: -6px !important;
    margin-left: -12px !important;
  } */
  /* .MuiFormControl-root:focus-within label{
    margin: 0 !important;
  } */

  .css-mm42gz-MuiButtonBase-root-MuiFab-root {
    width: 35px !important;
    height: 35px !important;
  }
  /*.css-mm42gz-MuiButtonBase-root-MuiFab-root img{
    width: 75% !important;
    height: 75% !important;
  } */
  .css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root {
    padding-left: 3px !important;
    padding-right: 3px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .upload-profile-btn i {
    font-size: 2rem;
  }
}

.MuiInputBase-root input::placeholder {
  font-size: 1.2rem !important;
  opacity: 0.75;
}

@media only screen and (max-width: 320px) {
  .css-mm42gz-MuiButtonBase-root-MuiFab-root {
    width: 30px !important;
    height: 30px !important;
  }
  /* .MuiInputBase-root input
  {
    padding: 8px 6px !important;
  }
  .MuiInputBase-root input::placeholder   {
    padding: 0 !important;
  }
  .MuiFormControl-root label{
    margin-top: -8px !important;
    margin-left: -14px !important;
  } */
  /* .MuiFormControl-root label:focus{
    margin: 0 !important;
  } */
  /* .MuiInputBase-formControl .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus label{
    margin: 0 !important;
  } */
  .sm-fs_1rem {
    font-size: 1rem !important;
  }
}

.active
  span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig,
.active .css-vubbuv {
  color: var(--yellow) !important;
}
.css-16ythuc-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input::placeholder,
.MuiInputBase-formControl
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input::placeholder,
.MuiInputBase-formControl
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input::placeholder,
.MuiInputBase-formControl
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  color: var(--yellow) !important;
  font-size: 0.9rem !important;
}
/* .MuiInputBase-formControl .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  padding-bottom: 18px !important;
} */
.displayInstructions {
  /* align-self: start; */
  padding-left: 56px;
  margin-top: -30px;
  line-height: 1.2;
}
.resposError {
  padding-left: 56px;
  margin-top: -22px;
  line-height: 1.2;
}

@media only screen and (max-width: 375px) {
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    padding-left: 8px !important;
    min-height: 30px !important;
  }
  .css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
    font-size: 0.9rem !important;
    min-height: 30px !important;
    padding: 4px !important;
    text-overflow: ellipsis !important;
  }
  .displayInstructions {
    padding-left: 41px;
    margin-top: -16px;
  }
  .resposError {
    padding-left: 41px;
    margin-top: -12px;
  }
}
.css-gdh49b-MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected='true'].Mui-focused,
.css-gdh49b-MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected='true'],
.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: var(--second) !important;
  color: var(--yellow) !important;
  border-bottom: 1px solid var(--yellow) !important;
}
.autocomplete-select-field input::placeholder {
  color: var(--yellow) !important;
}

.img-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
}
.img-container .card-img-top {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 1600px) {
  .column-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .custom-column {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1600px) {
  .column-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .custom-column {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .address_item .address-cards {
    padding: 0.8rem 1.5rem !important;
  }
  .column-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .custom-column {
    flex: 0 0 auto;
    width: 33.3333333%;
  }
  .card-text span.hash-tag {
    font-size: 0.75rem !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .address_item .address-cards {
    padding: 0.8rem 1.5rem !important;
  }
  .column-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .custom-column {
    flex: 0 0 auto;
    width: 50%;
  }
  .card-text span.hash-tag {
    font-size: 0.7rem !important;
  }
}
@media only screen and (min-width: 420px) and (max-width: 576px) {
  .address_item .address-cards {
    padding: 0.6rem 1rem !important;
  }
  .column-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .custom-column {
    flex: 0 0 auto;
    width: 85%;
  }
  .card-text span.hash-tag {
    font-size: 0.7rem !important;
  }
}

@media only screen and (max-width: 420px) {
  .address_item .address-cards {
    padding: 0.6rem 1rem !important;
  }
  .column-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .custom-column {
    flex: 0 0 auto;
    width: 100%;
  }
  .card-text span.hash-tag {
    font-size: 0.7rem !important;
  }
}

.MuiInputBase-input.css-1x5jdmq::placeholder {
  color: var(--yellow) !important;
}
